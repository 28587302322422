<template>
  <a-row>
    <a-row class="content-title" type="flex">
      <h2 class="flex-1">
        {{ $route.meta.title[$i18n.locale] }}
      </h2>
      <a-row class="flex-no__wrap" type="flex">
        <a-button
          type="primary"
          style="margin-right: 16px"
          @click="
            $router.push(
              {
                name: 'senate-leadership-authority',
                params: { menu_id: menu_id },
                query: { post_group: 'senate-leadership-authority' },
              },
              () => null
            )
          "
        >
          <a-icon type="file" />{{ $t("Tasks") }}</a-button
        >
        <a-button type="primary" @click="modalVisible = true">
          <a-icon type="plus" />{{ $t("Add") }}</a-button
        >
      </a-row>
    </a-row>
    <a-table
      :columns="columns"
      :data-source="list"
      :loading="loading"
      :pagination="false"
      :row-key="(record) => record.id"
    >
      <template slot="id" slot-scope="item">
        <b>{{ item.id }}</b>
      </template>
      <template slot="person" slot-scope="item">
        {{ item.person && item.person.full_name }}
      </template>
      <template slot="type" slot-scope="item">
        {{ findType(item.type) }}
      </template>
      <template slot="position" slot-scope="item">
        {{ item.position }}
      </template>
      <template slot="operation" slot-scope="item">
        <a-row class="btns" type="flex" align="middle" justify="end">
          <a-button type="primary" class="edit-btn" @click="updateItem(item)">
            <a-icon type="edit" class="action-btns" />
          </a-button>
          <a-popconfirm
            :cancel-text="$t('No')"
            :ok-text="$t('Yes')"
            :title="$t('APopconfirm')"
            @confirm="removeItem(item.id)"
          >
            <a-button type="primary">
              <a-icon class="action-btns" type="delete" />
            </a-button>
          </a-popconfirm>
        </a-row>
      </template>
    </a-table>
    <a-modal
      :visible="modalVisible"
      :title="$t('Add')"
      :closable="false"
      width="600px"
      v-if="modalVisible"
    >
      <a-row align="middle" class="flex-baseline" style="margin-bottom: 15px">
        <a-col :span="4" style="text-align: right; padding-right: 8px">
          {{ $t("Senator") }}:
        </a-col>
        <a-col :span="20">
          <a-row align="middle" class="flex-baseline flex-wrap">
            <a-select
              v-model="form.person_id"
              :placeholder="$t('Select')"
              style="width: 100%"
            >
              <a-select-option
                v-for="item in senatorList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-row>
        </a-col>
      </a-row>
      <a-row align="middle" class="flex-baseline" style="margin-bottom: 15px">
        <a-col :span="4" style="text-align: right; padding-right: 8px">
          {{ $t("Position") }}
        </a-col>
        <a-col :span="20">
          <a-row align="middle" class="flex-baseline flex-wrap">
            <a-select v-model="form.type" :placeholder="$t('Select')" style="width: 100%">
              <a-select-option
                v-for="item in typeList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.name && item.name[$i18n.locale] }}
              </a-select-option>
            </a-select>
          </a-row>
        </a-col>
      </a-row>
      <a-row align="middle" class="flex-baseline" style="margin-bottom: 15px">
        <a-col :span="4" style="text-align: right; padding-right: 8px">
          {{ $t("Order") }}:
        </a-col>
        <a-col :span="6">
          <a-row align="middle" class="flex-baseline flex-wrap">
            <a-input
              type="number"
              v-model="form.position"
              :placeholder="$t('Placeholder')"
            />
          </a-row>
        </a-col>
      </a-row>
      <template slot="footer">
        <a-button key="back" @click="closeModal">{{ $t("Close") }}</a-button>
        <a-button key="save" type="primary" class="edit-btn" @click="createItem">{{
          $t("Save")
        }}</a-button>
      </template>
    </a-modal>
  </a-row>
</template>

<script>
export default {
  data() {
    return {
      form: {
        person_id: null,
        menu_id: 18,
        menu_category: "leadership",
        type: null,
        position: null,
      },
      menu_id: 18,
      menu_category: "leadership",
      langTab: "oz",
      list: [],
      typeList: [
        {
          value: 1,
          name: {
            uz: "Rahbar",
            oz: "Раҳбар",
            ru: "Руководитель",
            en: "Руководитель",
          },
        },
        {
          value: 2,
          name: {
            uz: "O'rinbosar",
            oz: "Ўринбосар",
            ru: "Заменять",
            en: "Заменять",
          },
        },
        {
          value: 3,
          name: {
            uz: "Yordamchi",
            oz: "Ёрдамчи",
            ru: "Ассистент",
            en: "Ассистент",
          },
        },
      ],
      senatorList: [],
      loading: false,
      columns: [
        {
          width: "60px",
          key: "id",
          title: "Id",
          scopedSlots: { customRender: "id" },
          fixed: "left",
        },
        {
          title: this.$t("Senator"),
          key: "person",
          scopedSlots: { customRender: "person" },
          width: "40%",
        },
        {
          title: this.$t("Position"),
          key: "type",
          scopedSlots: { customRender: "type" },
          width: "20%",
        },
        {
          title: this.$t("Order"),
          key: "position",
          scopedSlots: { customRender: "position" },
          width: "10%",
        },
        {
          title: "",
          key: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      modalVisible: false,
    };
  },
  computed: {
    getKey() {
      return this.langTab;
    },
  },
  async created() {
    await this.fetchData();
    try {
      const { data } = await this.$api.get("/admin/person/list", {
        params: { type: 1 },
      });
      this.senatorList = (data && data.data.results) || [];
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        const { data } = await this.$api.get("/admin/person-group/list", {
          params: {
            menu_id: this.menu_id,
            menu_category: this.menu_category,
          },
        });
        this.list = (data && data.data.results) || [];
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error(error);
      }
    },
    async createItem() {
      try {
        this.form.position = +this.form.position;
        if (this.form.id) {
          let { data } = await this.$api.put(
            `/admin/person-group/${this.form.id}`,
            this.form
          );
          if (data.status) {
            await this.fetchData();
            this.$message.success("Успешно");
            this.closeModal();
          } else {
            this.$message.error(data.message);
          }
        } else {
          let { data } = await this.$api.post("/admin/person-group/create", this.form);
          if (data.status) {
            await this.fetchData();
            this.$message.success("Успешно");
            this.closeModal();
          } else {
            this.$message.error(data.message);
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async removeItem(id) {
      try {
        const { data } = await this.$api.delete(`/admin/person-group/${id}`);
        if (data.status) {
          this.$message.success("Успешно удалена");
          this.fetchData();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async updateItem(item) {
      this.form.id = item.id;
      this.form.person_id = item.person && item.person.id;
      this.form.type = item.type;
      this.form.position = item.position;
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
      this.form = {
        person_id: null,
        menu_id: 18,
        menu_category: "leadership",
        type: null,
        position: null,
      };
    },
    findType(type) {
      return this.typeList.find((el) => el.value == type).name[this.$i18n.locale];
    },
  },
};
</script>
